.App {
  font-family: 'Luciole';
}

.hidden {
  position: absolute;
  left: -10000em;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

@font-face {
  font-family: 'Luciole';
  src: local('Luciole'), url(/public/fonts/luciole/Luciole-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Luciole-Bold';
  src: local('Luciole-Bold'), url(/public/fonts/luciole/Luciole-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Luciole-Italic';
  src: local('Luciole-Italic'), url(/public/fonts/luciole/Luciole-Regular-Italic.ttf) format('truetype');
}

@font-face {
  font-family: 'Luciole-Bold-Italic';
  src: local('Luciole-Bold-Italic'), url(/public/fonts/luciole/Luciole-Bold-Italic.ttf) format('truetype');
}